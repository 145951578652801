import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useLayoutEffect, useState } from 'react'

import text from './text.json'

type Contents = (typeof text)['en']
const validLanguageCodes = Object.keys(text)

const browser = typeof window !== 'undefined' && window

export default memo(function Error404() {
  const [contents, setContents] = useState<Contents>(text.en)

  useLayoutEffect(() => {
    const language =
      (window.navigator as any).userLanguage || window.navigator.language
    const languageCode = language.substring(0, 2).toLowerCase()

    if (validLanguageCodes.includes(languageCode)) {
      setContents(text[languageCode as 'en'])
    }
  }, [])

  return (
    browser && (
      <Container>
        <Wrapper>
          <Logo
            src="/logo.svg"
            width="100"
            height="101"
            alt="iQ Hotel Milano"
          />
          <Title>{contents.title}</Title>
          <Text>{contents.text}</Text>
          <CTA
            label={useVocabularyData('back-to-home', contents.code)}
            URL={`/${contents.prefix}`}
          />
        </Wrapper>
      </Container>
    )
  )
})

const Container = styled(FlexBox)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  text-align: center;

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  margin-bottom: 2.11vw;

  @media (max-width: 1199px) {
    width: auto;
    height: 3.75rem;
  }
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;

  @media (max-width: 991px) {
    font-size: 41px;
  }
`

const Text = styled.div`
  margin-top: 2.11vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
  }
`

const CTA = styled(Button)`
  margin: 2.11vw auto 0;
`
